.shop-cat-container {
  .counter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f3f3f3;
    padding: 5px 10px;
    margin-bottom: 20px;

    svg {
      width: 15px;
      margin-right: 10px;
    }
  }
}
// shop-cat-container ends

.product-container {
  .prd-details {
    .prd-img {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-height: 300px;
      }
    }
    .offer {
      padding: 5px 10px;
      font-size: 1.3em;
      font-weight: normal;
    }
    .title {
      padding: 10px 0 0;
      font-size: 2em;
      font-weight: bold;
    }
    .price {
      padding-top: 10px;
      color: #c0c0c0;
      font-size: 1.3em;
      span {
        color: #5eba00;
        font-size: 1.5em;
        font-weight: bold;
      }
      &.old span {
        color: #666;
        text-decoration: line-through;
        font-size: 1em;
        font-weight: normal;
      }
    }
    .discount {
      padding: 0 0;
      font-size: 1.3em;
      span {
        font-weight: bold;
        font-size: 1.4em;
        color: #5eba00;
      }
    }
    .wt {
      .label {
        padding-bottom: 10px;
        color: #aaa;
      }
    }

    .cart-btn {
      &.in-cart {
        .tick-success {
          width: 24px !important;
        }
      }
      button {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .tick-success {
        width: 0;
        overflow: hidden;
        transition: 0.3s ease-in all;
        margin-left: 20px;
        svg {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}
// product-container ends
