body {
  font-family: sans-serif;
  font-size: 13px;
  color: #333;
}

ul,
ol,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}
a {
  color: #000;
  text-decoration: none;
  outline: 0;
  &:hover {
    color: #4a9103;
    text-decoration: none;
  }
}
h1 {
  margin: 0;
  padding: 0;
  padding-bottom: 0px;
  padding-bottom: 1em;
  font-size: 2.2em;
  font-weight: 400;
  color: #000;
  font-family: Pacifico, cursive;
}
h2 {
  font-size: 1.6em;
}
h3 {
  font-size: 1.3em;
  margin: 0 0 1.5em;
  padding: 0.5em 0;
  border-bottom: 1px dashed #000;
}

h2,
h3 {
  font-weight: 700;
  color: #000;
  text-transform: uppercase;
}
h1,
h2,
h3 {
  span {
    color: #4a9103;
  }
  span.red {
    color: #f06040;
  }
}
label {
  padding: 0;
  margin: 0;
}

.cursor {
  cursor: pointer;
}
.site-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}
.container {
  max-width: 1280px;
  /* margin: auto; */
}

.box-green {
  background: #4a9103;
  color: #fff;
}

.pad-20 {
  padding-right: 20px;
  padding-left: 20px;
}

.pad-40 {
  padding-right: 40px;
  padding-left: 40px;
}

.line {
  .lite {
    border-bottom: 1px solid #e1e1e1;
  }
  .thick {
    border-bottom: 2px solid #000;
  }
}

.btn-pill {
  border-radius: 10rem;
  padding-left: 1.5em;
  padding-right: 1.5em;
}
.btn-success {
  color: #fff;
  background-color: #5eba00;
  border-color: #5eba00;
}

.btn.anim {
  svg {
    width: 0;
    overflow: hidden;
    transition: 0.3s ease-in all;
  }

  &:hover {
    svg {
      width: 18px;
      margin: 0 10px;
    }
  }
}

.stamp {
  color: #fff;
  background: #868e96;
  background-color: rgb(134, 142, 150);
  display: inline-block;
  min-width: 2rem;
  height: 2rem;
  padding: 0 0.25rem;
  line-height: 2rem;
  text-align: center;
  border-radius: 3px;
  font-weight: 600;

  .h3 {
    font-size: 1.1em;
  }
}

.page-placeholder {
  display: flex;
  justify-content: center;
  img {
    width: 200px;
  }
}
