.cart-common {
  &.order-summary {
    display: grid;
    grid-gap: 10px;
    .total {
      font-size: 1.2em;
      span {
        color: #000;
        font-weight: cold;
      }
    }

    .discount {
      font-size: 1.2em;
      span {
        color: #f06040;
        font-weight: cold;
      }
    }
    .total-final {
      font-weight: bold;
      font-size: 1.7em;
      span {
        color: #5eba00;
      }
    }
  }
}

.cart-container {
  .header {
    @media screen and (min-width: 576px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .steps {
      display: grid;
      grid-template-columns: auto auto auto;
      align-items: center;

      .bubble {
        grid-area: 1/2;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        padding: 10px;
        background: #000;
        border-radius: 40px;
        color: #fff;
        transition: 0.3s ease-in all;
        border: 5px solid aliceblue;

        &.b1 {
          grid-area: 1/1 !important;
        }
        &.b3 {
          grid-area: 1/3 !important;
        }

        &.active {
          background: #5eba00;
          border: 5px solid #5eba00;
          font-weight: bold;
        }
      }

      .line {
        grid-area: 1/1/-1/-1;
      }
    }
  }
  .card-body {
    display: flex;
    .thumb {
      height: 100px;
      width: 100px;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    .details {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      .item-price {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .price {
          display: flex;
          align-items: center;
          label {
            display: flex;
            margin-right: 15px;
            font-size: 1.2em;
            span {
              font-size: 0.8em;
              color: #f06040;
              padding-left: 0.7em;
              text-decoration: line-through;
            }
          }
        }
        .p-sum {
          font-size: 1.3em;
          font-weight: bold;
          color: #000;
        }
      }
      .item-name {
        padding: 10px 0;
        font-size: 1.3em;
      }
      .item-misc {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .wt {
          font-size: 1.2em;
          font-weight: bold;
        }
        .qty {
          span {
            padding: 20px;
          }
        }
      }
    }
  }
}

.shipping-container {
  .item {
    .card {
      height: 160px;
      justify-content: space-between;
      transition: 0.3s ease-in all;
      &:hover {
        border-color: #2a2a2a;
        cursor: pointer;
      }
      .active-stamp {
        display: none;
      }
    }
    &.active .card {
      border-color: #000;
      .active-stamp {
        display: block !important;
      }
    }

    &.new .card {
      justify-content: center;
      align-items: center;
      box-shadow: inset 0 0 150px #fffee5;
      font-size: 1.3em;
      font-weight: bold;
      .icon {
        padding-bottom: 30px;
      }
    }
  }
}

.payment-container {
  .payment-methods {
    .card.inactive {
      opacity: 0.9;
    }
    .card:not(.inactive):hover {
      border-color: #2a2a2a;
      cursor: pointer;
    }
    .card.active {
      border-color: #000;
      box-shadow: inset 0 0 150px #fffee5;
      .stamp {
        background-color: #28a745 !important;
      }
    }
    .card-body {
      display: flex;
      align-items: center;
      .deets {
        display: flex;
        flex-direction: column;
        padding: 0 15px;
        div {
          padding: 10px 0;
          font-size: 1.3em;
          font-weight: bold;
        }
        .sub {
          font-size: 0.8em;
          color: red;
        }
      }
    }
  }
}
// payment-container ends

.delivery-methods {
  .card {
    transition: 0.3s ease-in all;
    &.inactive {
      opacity: 0.9;
    }
    &:not(.inactive):hover {
      border-color: #2a2a2a;
      cursor: pointer;
    }
    &.active {
      border-color: #000;
      box-shadow: inset 0 0 150px #fffee5;
      .icon {
        color: #28a745 !important;
        svg {
          height: 50px;
        }
      }
    }
  }
  .card-body {
    position: relative;
    display: flex;
    height: 300px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 840px) {
      display: grid;
      height: 100px;
      grid-template-columns: auto auto;
      grid-template-rows: auto auto;
      grid-column-gap: 20px;
      justify-content: left;
      .icon {
        grid-area: 1/1/-1/2;
      }
      .title {
        grid-area: 1/2/1/2;
        margin-top: 0 !important;
      }
      .subtitle {
        grid-area: 2/2;
      }

      .unavailable {
        left: 80px;
        bottom: 5px !important;
      }
    }

    .icon svg {
      height: 30px;
      transition: 0.3s ease-in all;
    }

    .title {
      font-size: 1.4rem;
      font-weight: bold;
      margin-top: 30px;
    }

    .unavailable {
      position: absolute;
      bottom: 40px;
      margin-top: 20px;
      font-size: 0.8em;
      color: red;
    }
  }
}
// delivery-methods ends

.order-placed-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .icon-pop {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 150px;
  }

  .icon-busy-block {
    position: relative;
    height: 150px;
    width: 150px;
    .icon-outer {
      color: #a6a6a6;
      animation: animPulse 1s infinite both;
      svg {
        height: 150px;
        width: 150px;
      }
    }
    .icon-anim {
      position: absolute;
      top: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 150px;
      height: 150px;
      svg {
        width: 60px;
        height: 130px;
      }
    }
  }

  .icon-success-block {
    width: 0;
    height: 0;
    border-radius: 100px;
    background: #4a9103;
    padding: 20px;
    overflow: hidden;
    animation: animiconPop 0.2s 0.2s both;

    .icon-anim {
      width: 0px;
      overflow: hidden;
      color: #fff;
      animation: animateOrderSuccess 0.6s 0.5s both;
    }
    svg {
      width: 110px;
      height: 130px;
    }
  }
}

.icon-danger-block {
  width: 150px;
  height: 150px;
  border-radius: 100px;
  background: #f06040;
  color: #fff;
  padding: 10px 20px;
  overflow: hidden;

  svg {
    width: 110px;
    height: 130px;
  }
}

@keyframes animPulse {
  from {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes animiconPop {
  to {
    height: 150px;
    width: 150px;
  }
}

@keyframes animateOrderSuccess {
  from {
    width: 0;
  }

  to {
    width: 150px;
  }
}
// order-placed-container ends
