.common {
  &.items-rotator {
    .product-item {
      width: 180px;
      border-top: 2px solid #fff;
      border-bottom: 2px solid #fff;
      padding: 20px 0;

      &:hover {
        border-top: 2px solid #000;
        border-bottom: 2px solid #000;
      }

      &.no-stock {
        .unavailable {
          display: block !important;
        }
        .button {
          display: none !important;
        }
        .thumb img {
          opacity: 0.4;
        }
      }

      .thumb {
        display: grid;
        align-items: center;
        justify-items: center;
        height: 200px;
        padding: 0 10px;
        img {
          max-width: 100%;
        }
        a {
          grid-area: 1/1;
        }
        .unavailable {
          grid-area: 1/1;
          background: #f3f3f3;
          border: 1px dashed #2a2a2a;
          padding: 5px 20px;
          font-weight: bold;
          display: none;
          z-index: 1;
        }
      }
      .details {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        height: 100px;

        .title {
          max-height: 50px;
          font-size: 1.3em;
          font-weight: 700;
          line-height: 1.4em;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .p-wrap {
          display: flex;
          align-items: center;
          .badge {
            margin-left: 10px;
          }
        }
        .price {
          display: flex;
          align-items: flex-start;
          padding: 5px 0;
          font-size: 1.3em;
          color: #2a2a2a;
          .off {
            padding-left: 0.5em;
            font-size: 0.6em;
            color: #f06040;
            text-decoration: line-through;
          }
        }
      }

      .button {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 0.5em;

        &.in-cart {
          .tick-success {
            display: flex !important;
          }
        }

        .tick-success {
          position: absolute;
          right: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 30px;
          height: 30px;
          background: #fff;
          border-radius: 20px;
          border: 3px solid #5eba00;
          color: #000;
          display: none;
        }
      }
    }
    // product-item ends

    .header {
      display: flex;
      justify-content: space-between;
    }
    .scroll-view {
      width: 100%;
      overflow-x: auto;
      .list-wrapper {
        display: flex;
      }
    }
  }
}
// items-rotator

.items-grid {
  a:hover {
    color: inherit !important;
  }
  .card:hover {
    box-shadow: 0 0 2px #e1e1e1;
    .card-footer {
      background: #dfdfdf;
    }
  }
  .thumb {
    display: grid;
    align-items: center;
    justify-items: center;
    height: 150px;
    img {
      max-width: 100%;
      max-height: 150px;
    }
    a {
      grid-area: 1/1;
    }
    .unavailable {
      grid-area: 1/1;
      background: #f3f3f3;
      border: 1px dashed #2a2a2a;
      padding: 5px 20px;
      font-weight: bold;
      display: none;
      z-index: 1;
    }
  }

  .p-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    .off-label {
      color: #5eba00;
      font-weight: bold;
    }
  }

  .details {
    .title {
      display: flex;
      align-items: flex-end;
      height: 35px;
      word-break: break-all;
    }
    .price {
      display: flex;
      align-items: flex-start;
      padding: 5px 0;
      font-size: 1.3em;
      font-weight: bold;
      .off {
        padding-left: 0.5em;
        font-size: 0.6em;
        color: #f06040;
        text-decoration: line-through;
        font-weight: normal;
      }
    }
  }
}
// items-grid ends

.content-placeholder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 400px;

  img {
    max-width: 250px;
  }
}

.site-loader-anim {
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  width: 100%;
  height: 400px;

  .bg {
    grid-area: 1/1;
    background: url("../assets/placeholders/loader-img.svg");
    background-size: 200px;
    background-repeat: repeat-x;
    background-position: center;
    animation: loaderRoate 5s infinite linear;
    height: 400px;
    width: calc(100vw - 20px);
    max-width: 500px;
    box-shadow: inset 125px 0 1000px #fff;
  }

  .overlay {
    grid-area: 1/1;
    box-shadow: inset -125px 0 1000px #fff;
  }
}

@keyframes loaderRoate {
  from {
    background-position: 0;
  }

  to {
    background-position: -200px;
  }
}
