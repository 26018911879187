.home {
  &.banner-wrapper img {
    width: 100%;
    margin-bottom: 30px;
  }

  .menu-highlight {
    @media screen and (max-width: 800px) {
      display: none;
    }
    li {
      color: #333;
      font-size: 1.8em;
      padding: 0.3em 0;
      text-transform: uppercase;
      font-weight: 700;
    }
  }
}
