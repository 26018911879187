.website-loader {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  background: #fff;
  z-index: 999999;
  img {
    width: 150px;
  }
}
.header-offers {
  background: #f3f3f3;
  border: 0;
  .message {
    font-size: 10px;
    color: #8a877f;
    padding: 0.8em 0px;
    @media screen and (max-width: 840px) {
      padding: 0.8em 20px;
      text-align: center;
    }
  }
}
.main-menu {
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background: #fff;
  box-shadow: 0 50px 100px #ffffffa6;
  z-index: 1;

  .menu-wrapper {
    display: flex;
    align-items: center;
    // padding: 20px 40px;

    @media screen and (max-width: 840px) {
      // padding: 20px 0;
      .explore-trigger {
        display: block !important;
      }

      .logo {
        width: 110px !important;
      }
    }

    .explore-trigger {
      display: none;
    }

    .logo {
      width: 125px;
      margin-right: 20px;
      img {
        width: 100%;
      }
    }
    .menu-bar {
      @media screen and (max-width: 840px) {
        display: none;
      }
      ul {
        display: flex;
        align-items: center;
      }
      ul > li {
        margin-right: 50px;
        border-left: 1px solid #e1e1e1;
        font-size: 1.2em;
        text-transform: uppercase;
        font-weight: 700;

        .hover-menu {
          position: relative;
          display: flex;
          z-index: 9;

          &:hover {
            .child-menu {
              display: block;
            }

            .overlay {
              position: fixed;
              bottom: 0;
              left: 0;
              width: 100vw;
              height: calc(100vh - 130px);
              background: #fff;
              opacity: 0.5;
            }
          }

          .item {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 200px;
            .ico {
              margin-right: 20px;
              width: 29px;
            }
          }

          .child-menu {
            display: none;
            position: absolute;

            .sub-menu {
              position: relative;
              left: 0px;
              top: 63px;
              width: 200px;
              padding: 40px 0;
              box-shadow: 0px 0px 2px #2a2a2a;

              &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 98px;
                display: block;
                width: 18px;
                height: 18px;
                background: #4a9103;
                -webkit-transform: rotate(45deg) translateX(-50%);
                transform: rotate(45deg) translateX(-50%);
              }

              a {
                color: #fff;
              }
              a:hover {
                text-decoration: none;
              }

              div {
                padding: 10px 0;
                text-align: center;

                &:hover {
                  background: #000;
                }
              }
            }
          }
          // child-menu ends
        }
      }
    }
  }
  // .menu-wrapper ends
  .info-bar {
    display: flex;
    align-items: center;
    // padding: 0 40px;
    color: #706e66;
    font-size: 1.5em;

    @media screen and (max-width: 840px) {
      // padding: 0;
      .support {
        display: none;
      }
    }

    .item {
      margin-left: 30px;
      .cart-ico {
        display: flex;
        align-items: center;
      }
      i {
        margin-right: 10px;
        color: #4a9103;
      }
      .cart-count {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #000;
        border-radius: 25px;
        width: 35px;
        height: 35px;
        margin-left: 10px;
        font-size: 0.7em;
        color: #fff;
        font-weight: 700;
        border: 5px solid #fff;
      }

      &.search {
        color: #000;
      }
    }
  }
}
// main-menu ends

.mobile-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  margin-right: 10px;
  z-index: 9;
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    opacity: 0.9;
  }

  .close-menu {
    position: fixed;
    left: 40px;
    top: 40px;
  }

  .list {
    z-index: 1;
    div {
      padding: 10px 0;
      font-size: 1.5em;
      font-weight: bold;
      text-transform: uppercase;
      text-align: center;
    }
  }
}
// mobile-menu ends

.footer-wrapper {
  background: #f3f3ef;
  border-top: 1px solid #e1e1e1;
  padding: 1em 0;

  .credits {
    display: flex;
    align-items: center;
    .heart-wrapper {
      display: flex;
      justify-content: center;
      width: 25px;
    }
  }
  &:hover {
    .heart {
      animation: heartBeat 1s infinite both;
      // transition: 0.3s ease all;
    }
  }
}

#cart-counter.anim {
  animation: cartPop 1s both;
}

@keyframes cartPop {
  from {
    border-color: #5eba00;
    background: #5eba00;
  }

  to {
    border-color: #fff;
    background: #000;
  }
}

@keyframes heartBeat {
  from {
    width: 15px;
    color: #333;
    fill: #333;
  }

  50% {
    width: 20px;
    color: #c92243;
    fill: #c92243;
  }
  to {
    width: 15px;
    color: #333;
    fill: #333;
  }
}
